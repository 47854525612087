<template>
  <div>
    <ol class="breadcrumb float-xl-right">
      <li class="breadcrumb-item">
        <router-link :to="{name:'dashboard'}">Dashboard</router-link>
      </li>
      <li class="breadcrumb-item"><a href="#">Users</a></li>
    </ol>
    <h1 class="page-header">Users</h1>
    <panel noButton="true" title="Users" variant="panel-default">
      <loading v-if="! is_loaded"/>
      <template v-else>
        <div class="row">
          <div class="input-group col-md-4 mb-3">
            <input
                v-model="search"
                class="form-control py-2 border-right-0 border"
                placeholder="Search ..."
                type="search"
            >
            <span class="input-group-append">
            <button
                class="btn btn-outline-secondary border-left-0 border"
                type="button"
            >
              <i class="fa fa-search"/>
            </button>
          </span>
          </div>
          <div class="col-md-8">
            <router-link v-if="$can.view($store.getters.getAuthUser, 'create-users')" class="btn pull-right btn-success" :to="{ name: 'users.create'}">
              <i class="fa fa-user-plus"></i> Create User
            </router-link>
            <router-link v-if="$can.view($store.getters.getAuthUser, 'verify-user-phone')" class="btn pull-right btn-success" :to="{ name: 'users.phone'}">
              <i class="fa fa-edit"></i> Link Phone
            </router-link>
            <button v-if="$can.view($store.getters.getAuthUser, 'export-users')"
                class="btn btn-secondary mr-2 pull-right"
                @click="exportUsers"
            >
              <i class="fa fa-download"></i> Export
            </button>
          </div>
        </div>
        <table
            class="table table-striped table-responsive-sm"
        >
          <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Status</th>
            <th>Created At</th>
            <th v-if="$can.view($store.getters.getAuthUser, 'view-user')">
              Actions
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="(user, i) in users"
              :key="i"
          >
            <td>{{ pagination.from + i }}</td>
            <td>{{ fullname(user) }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.role | unslug }}</td>
            <td>{{ user.active ? 'Active' : 'Inactive' }}</td>
            <td>{{ user.created_at }}</td>
            <td v-if="$can.view($store.getters.getAuthUser, 'view-user')">
              <router-link
                  :to="{name: 'users.show', params: { id: user.user_unique_id }}"
                  class="btn btn-sm btn-warning"
              >
                show
              </router-link>
            </td>
          </tr>
          <tr v-if="!users.length">
            <td :colspan="$can.view($store.getters.getAuthUser, 'view-user') ? 8 : 7">
              <div class="alert alert-info">
                No users found
              </div>
            </td>
          </tr>
          </tbody>
        </table>
        <pagination
            v-if="pagination.last_page > 1"
            :offset="5"
            :pagination="pagination"
            @paginate="fetch"
        />
      </template>
    </panel>
  </div>
</template>

<script>
import helper from "@/mixins/helper";

export default {
  mixins: [helper],
  data() {
    return {
      search: '',
      users: [],
      is_loaded: false,
      pagination: {
        'current_page': 1,
        'last_page': 1,
        'from': 1
      }
    }
  },
  watch: {
    search(o, n) {
      if (!n && !o) {
        return
      }
      this.fetchEntriesDebounced()
    }
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.is_loaded = false;
      this.$axios.get('/api/v1/users', {
        params: {
          page: this.pagination.current_page,
          search: this.search
        }
      }).then(response => {
        this.is_loaded = true;
        if (!Object.prototype.hasOwnProperty.call(response.data, "data")) return;
        this.users = response.data.data;
        this.pagination = this.pagination = (({total, per_page, current_page, last_page, from, to}) => ({
          total,
          per_page,
          current_page,
          last_page,
          from,
          to
        }))(response.data);
      });
    },
    exportUsers() {
      this.is_loaded = false;
      this.$axios({
        url: '/api/v1/users?export=xlsx',
        method: 'GET',
        responseType: 'arraybuffer',
      }).then((response) => {
        this.is_loaded = true;
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'users_export.xlsx');
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    fetchEntriesDebounced() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.fetch()
      }, 2000)
    }
  },
}
</script>